@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import "colors";

@font-face {
  font-family: 'sofiapro-bold';
  src: url('./static/fonts/sofiapro-bold-webfont.woff2') format('woff2'),
  url('./static/fonts/sofiapro-bold-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'sofiapro-medium';
  src: url('./static/fonts/sofiapro-medium-webfont.woff2') format('woff2'),
  url('./static/fonts/sofiapro-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sofiapro-light';
  src: url('./static/fonts/sofiapro-light-webfont.woff2') format('woff2'),
  url('./static/fonts/sofiapro-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

html, body, h1, h2, h3, h4, h5, h6, .ui.menu, input, label, .header {
  font-family: 'sofiapro-medium', sans-serif;
}

body {
  overflow: hidden;
}

.ui.basic.segment.payvy-main-content {
  margin-left: 165px;
  background: rgba(0, 48, 63, 0.03);
  height: calc(100vh - 5em);

  @media (max-width: 991px) {
    margin-left: 0 !important;
  }
}

.ui.fixed.menu + .ui.segment {
  margin-top: 5em !important;
}


.mobile-only {
  #root {
    height: 100vh;
    overflow: hidden;
  }

  @media (min-width: 768px) {
    display: none !important;
  }

  .ui.button.payvy-action {
    padding: 10px;
  }
}

.tablet-and-mobile-only {
  @media (min-width: 991px) {
    display: none !important;
  }
}

.tablet-and-desktop-only {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.desktop-only {
  @media (max-width: 991px) {
    display: none !important;
  }
}

h1.payvy-tab-name {
  padding: 10px 10px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 50px;
  width: 100%;
}

h5.payvy-tab-name {
  width: 100%;
  padding: 5px;
}

.padded {
  padding: 10px 0 !important;
}

.payvy-darken-background {
  background: rgba(0, 48, 63, 0.03);
}

.payvy-white-background {
  background: white;
  padding: 0 !important;
  flex-direction: column;
  flex-wrap: initial;
}

.pushable > .pusher.payvy-pusher {
  overflow: auto;
  min-height: 0;
}

.payvy-darker {
  input {
    border: none !important;
    background: #f0f3f3 !important;

    &::placeholder, &::-webkit-input-placeholder {
      color: #a8b9bd !important;
      opacity: 1;
    }
  }
}

.ui.pointing.basic.label.prompt {
  white-space: pre-line;
}

.ui.modal.basic.modal {
  .description {
    > p {
      text-align: center;
    }
  }
}

.ui.modal.payvy-modal {
  > .header {
    font-family: "sofiapro-medium", sans-serif;
    border-bottom: none;
    color: #4D6E79;
    font-weight: 500;
    font-size: 24px;
    padding-bottom: 0;
  }

  label {
    color: #4D6E79;
    font-weight: 500;
    font-size: 18px;
  }

  .ui.checkbox input:focus ~ label {
    color: #4D6E79;
  }

  .message {
    text-align: left;

    .error-list {
      white-space: pre-line;
    }
  }
}

.ui.button.payvy-action {
  font-size: 13px;
  font-weight: 500;
  padding: 10px 50px;

  &.payvy-primary {
    background: #FB8A8E !important;
    border: 1px solid #FB8A8E !important;
    color: white !important;
  }

  &.contact-button {
    text-align: left;
    padding-left: 15px !important;
  }

  &.payvy-text {
    padding: 0 !important;
    background: none;
    border: 0;
    margin: 0;
    color: #F9585D;

    &:hover {
      text-decoration: underline;
    }
  }

  &.payvy-secondary {
    background: none !important;
    border: 1px solid #FB8A8E !important;
    color: #FB8A8E !important;

    .icon {
      background: none;
    }
  }

  &.payvy-green {
    background: none !important;
    border: 1px solid #006400 !important;
    color: #006400 !important;

    .icon {
      background: none;
    }
  }
}

.ui.button.payvy-borderless-action {
  font-size: 24px;
  font-weight: 500;
  padding: 0 !important;
  margin: 0 !important;

  &.payvy-primary {
    background: #FB8A8E !important;
    color: white !important;
  }

  &.payvy-secondary {
    background: none !important;
    border: none !important;
    box-shadow: none;
    color: #FB8A8E !important;

    .icon {
      background: none;
    }
  }
}

.ui.icon.button > .icon, .ui.icon.buttons .button > .icon {
  opacity: 1 !important; // TODO: This is a fix for current webkit, opacity under 1 makes items always on top of everything else.
}

.ui.popup.visible {
  &.danger, &.danger:before {
    background-color: #F9585D;
    color: white;
    opacity: 1;
  }
}

.react-toast-notifications__container {
  z-index: 1001 !important;
}

pre {
  width: 100%;
  text-align: left;
}

.ui.basic.borderless {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;

  &:hover {
    background-color: initial !important;

    i {
      color: $payvy-red !important;
    }
  }
}

.centered {
  margin: 0 auto !important;
  text-align: center;
}

.payvy-tab-name ~ .page-header-buttons {
  position: absolute;
  right: 0;
}

.display-inline-block {
  display: inline-block !important;
}

.no-padding {
  padding: 0 !important;
}

.no-pointer {
  cursor: default !important;
}

.capitalize {
  text-transform: capitalize;
}

//noinspection CssReplaceWithShorthandSafely
.loading-bar-signal {
  width: 100%;
  height: 2px;

  &.active {
    background: repeating-linear-gradient(to right, white 0%, #aaa 50%, white 100%);
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient .5s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.full-page-height {
  height: calc(100vh - 5em);
  margin: 0 !important;
  padding: 0 !important;

  &.centered {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .payvy-static-image {
    width: 33%;
    max-width: 250px;
  }
}

.PhoneInput {
  border: solid 1px rgb(153, 172, 178, 1);
  border-radius: 0.375rem;
  width: 100%;

  .PhoneInputCountry {
    padding-left: 0.5rem;
  }

  input {
    padding: 0.5rem;
    border-radius: 0 0.375rem 0.375rem 0;
  }
}

.hover-border:hover {
  border-style: solid;
  border-width: 1px;
  border-color: #ee8387;
}

.finix-iframe {
  iframe {
    width: 100%;
    height: 100%;
  }
}

/* move into PayvyLabeledCheckbox.scss */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #757575;
}

/* Remove this when tailwindcss is updated */
.min-h-48 {
  min-height: 12rem;
}

.dash-before-content:before {
  content: '-';
  padding-right: 1rem;
}

@layer base {
  html, body, h1, h2, h3, h4, h5, h6, .ui.menu, input, label, .header {
    font-family: 'sofiapro-medium', sans-serif;
  }
}

.recharts-tooltip-wrapper {
  outline: none;
}

.font-light {
  font-family: 'sofiapro-light', 'sofiapro-medium', sans-serif;
}

.font-medium {
  font-family: 'sofiapro-medium', sans-serif;
}

.font-bold {
  font-family: 'sofiapro-bold', 'sofiapro-medium', sans-serif;
}

/* These shouldn't be necessary, but tailwinds important "!" is not working in the project.
All these are added as tailwindcss selectors to the component, they are just under-prioritized,
so they are forced here through a better selector. */
.payvy-select-container-override .payvy-select-control-override {
  border-radius: 0.375rem;
  border-width: 0;
  background-color: rgb(247, 249, 249);
}

.quickbooks-button-wrapper {
  button {
    background-color: #2ca01c;
  }
}

.pointer-events-none.global-disable {
  button {
    pointer-events: none;
  }
}

.chat-messages {
  a {
    color: $payvy-red;
  }
}
