@import "../../../colors";

/* TODO: rework this into tailwindcss */
div.payvy-comment-block {
  position: sticky;
  top: 0;
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  border: 3px solid #ccd5d8;
  background-color: #f7f9f9;
  border-radius: 5px;
  padding: 0;
  margin: 0;

  i.icon {
    color: #ccd5d8;
  }

  textarea.payvy-comment-input__input::placeholder {
    color: #ccd5d8 !important;
  }

  &.sms {
    border-color: #e2abfc;
    background-color: #f3ddfd;

    .tabs {
      border-color: #e2abfc;
      color: #e2abfc;
    }

    i.icon {
      color: #e2abfc;
    }

    textarea.payvy-comment-input__input::placeholder {
      color: #e2abfc !important;
    }
  }

  &.error {
    border-color: darkred;
    background-color: red;

    .tabs {
      border-color: darkred;

      .character-count.error {
        color: white !important;
      }
    }

    i.icon {
      color: darkred;
    }

    textarea.payvy-comment-input__input::placeholder {
      color: darkred !important;
    }
  }

  &.email {
    border-color: #fa797d;
    background-color: #fdddde;

    .tabs {
      border-color: #fa797d;
      color: #fa797d;
    }

    i.icon {
      color: #fa797d;
    }

    textarea.payvy-comment-input__input::placeholder {
      color: #fa797d !important;
    }
  }

  &.email.sms {
    border-color: #00303f;
    background-color: #ccd6d9;

    .tabs {
      border-color: #00303f;
      color: #00303f;
    }

    i.icon {
      color: #00303f;
    }

    textarea.payvy-comment-input__input::placeholder {
      color: #00303f !important;
    }
  }

  &.call {
    border-color: #fa9379;
    background-color: #fef1ee;

    .tabs {
      border-color: #fa9379;

      .character-count {
        display: none;
      }
    }

    i.icon {
      color: #fa9379;
    }

    textarea.payvy-comment-input__input::placeholder {
      color: #fa9379 !important;
      font-size: 30px !important;
      opacity: 1;
    }
  }

  .payvy-comment-input {
    flex-grow: 1;
    border: none;
    position: relative;
    overflow-y: visible;

    textarea.payvy-comment-input__input {
      background-color: white !important;
      font-weight: 500 !important;
      padding: 5px 10px;
      border: none;
      margin-top: 2px;
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      font-family: inherit;
      font-size: inherit;
      letter-spacing: inherit;
      height: 100%;
      bottom: 0;
      overflow: hidden;
      resize: none;

      &:focus, &:focus-within {
        border: none !important;
        outline: none;
      }

      &::placeholder {
        color: #ccd5d8;
      }
    }
  }

  .tabs {
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 5px;
    border-top: 3px solid #ccd5d8;
    color: #ccd5d8;

    .error-message {
      position: absolute;
      bottom: -45px;
      background: $payvy-red;
      color: white;
      width: 100%;
      padding: 10px;
      border: 2px solid $payvy-red-lighter;
      border-radius: 10px;
      text-align: center;
    }

    .character-count {
      font-size: 10px;
      float: left;
      padding: 15px 0 0 5px;

      &.error {
        color: $payvy-red !important;
      }
    }

    .icon {
      padding: 2px;
      margin: 3px;
    }

    .icon.item.call-icon {
      background-color: #fa9379;
      font-size: 12px;

      i {
        color: #fef1ee;
      }
    }

    .disabled {
      opacity: 0.3;
    }
  }
}

.payvy-comment-input__suggestions {
  border: 2px solid $payvy-red-light;
  border-radius: 5px;

  ul.payvy-comment-input__suggestions__list {
    li.payvy-comment-input__suggestions__item {
      padding: 5px;

      &.payvy-comment-input__suggestions__item--focused {
        background-color: $payvy-red-lighter;
      }

      span.payvy-comment-input__suggestions__item__display {
        b.payvy-comment-input__suggestions__item__highlight {
          color: $payvy-red
        }
      }
    }
  }
}
