$backgroundColor: white;
$weekendColor: #d10000;
$activeDayColor: #f9585d;
$activeSelectionColor: #c51818;
$activeHoverColor: #db2828;

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: $backgroundColor;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-radius: 0.375rem;
  padding: 0.150em;

  button {
    margin: 0;
    border: 0;
    outline: none;

    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.react-calendar--doubleView {
  width: 700px;

  .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;

    & > * {
      width: 50%;
      margin: 0.5em;
    }
  }
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;

  button {
    min-width: 44px;
    background: none;

    &:disabled {
      background-color: #f0f0f0;
    }
  }
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers {
  .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }
}

.react-calendar__month-view__days__day--weekend {
  color: $weekendColor;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;

  &:disabled {
    background-color: #f0f0f0;
  }
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: $activeDayColor;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: $activeDayColor;
  color: #FCE1E5;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: $activeSelectionColor;
  color: $backgroundColor;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: $activeHoverColor;
}

.react-calendar--selectRange {
  .react-calendar__tile--hover {
    background-color: $activeDayColor;
  }
}

.react-date-picker,
.react-daterange-picker {
  display: inline-flex;
  position: relative;
  width: 100%;
  margin-top: 2px;
}

.react-daterange-picker,
.react-date-picker,
.react-daterange-picker *,
.react-date-picker *,
.react-daterange-picker *:before,
.react-date-picker *:before,
.react-daterange-picker *:after,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-date-picker--disabled,
.react-daterange-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
  cursor: not-allowed;
}

.disabled {
  .react-date-picker__wrapper,
  .react-daterange-picker__wrapper {
    background-color: #f0f0f0;
  }
}

.react-date-picker__wrapper,
.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  align-items: center;
  background-color: #ffffff;
  padding: 0.40rem 0.25rem;
  border-radius: 0.375rem;

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
}

.react-date-picker__inputGroup,
.react-daterange-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  height: 100%;
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}

.react-date-picker__inputGroup__divider,
.react-daterange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-daterange-picker__inputGroup__divider,
.react-date-picker__inputGroup__divider,
.react-daterange-picker__inputGroup__leadingZero,
.react-date-picker__inputGroup__leadingZero {
  display: inline-block;
}

.react-date-picker__inputGroup__input,
.react-daterange-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  color: currentColor;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;

  &:invalid {
    background: rgba(255, 0, 0, 0.1);
  }

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
}

.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-date-picker__inputGroup__input--hasLeadingZero,
.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-date-picker__button, .react-daterange-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;

  &:enabled {
    cursor: pointer;
  }

  &:disabled {
    .react-date-picker__button__icon,
    .react-daterange-picker__button__icon {
      stroke: #6d6d6d;
    }
  }

  svg {
    display: inherit;
  }
}

.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button_icon {
  stroke: #0078d7;
}

.react-daterange-picker__calendar,
.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  z-index: 1;

  .react-calendar {
    border-width: thin;
  }
}

.react-daterange-picker__calendar--closed,
.react-date-picker__calendar--closed {
  display: none;
}
