@use "sass:color";

$payvy-red: #f9585d;
$payvy-red-light: color.adjust($payvy-red, $lightness: 15%, $space: hsl);
$payvy-red-lighter: color.adjust($payvy-red, $lightness: 31%, $space: hsl);
$quickbooks: #00a63b;

.text-danger {
  color: #db2828;
}

.ui.button.icon.payvy {
  border: 1px solid #ebeff1;
  border-radius: 4px;
  color: #a9babe;

  &.payvy-primary {
    background: #f2f5f5;

    &:hover {
      background: #F5F2F2;
      color: #BFAEAA;
    }
  }

  &.payvy-secondary {
    background: #ffffff;

    &:hover {
      background: $payvy-red;
      color: $payvy-red-light;
    }
  }

  &.danger {
    background-color: $payvy-red !important;
    color: $payvy-red-light !important;
  }
}
