@use "sass:color";

@use "../../../colors";

$commented-via-sms: #e2abfc;
$commented-via-email: #fa797D;
$called-via-phone: #fa9379;
$commented-via-sms-and-email: #00303f;
$payment-issue: #993333;

.div.payvy-history {
  margin-top: 10px;

  > .history-item {
    > .label {
      align-self: center !important;
    }

    .content .text.extra {
      margin: 0;
      white-space: pre-line;

      .mention {
        font-weight: 700;
      }

    }

    &.synced-from-quickbooks, &.quickbooks-sync {
      .label {
        i.icon:before {
          content: " ";
          position: relative;
          display: inline-block;
          width: 28px;
          height: 25px;
          -webkit-mask: url('static/quickbooks.svg') no-repeat 100% 100%;
          mask: url('static/quickbooks.svg') no-repeat 100% 100%;
          -webkit-mask-size: cover;
          mask-size: cover;
          background-color: colors.$quickbooks !important;
        }
      }

      .content {
        .date {
          color: color.adjust(colors.$quickbooks, $saturation: -40%, $space: hsl) !important
        }

        .summary, .text.extra {
          color: colors.$quickbooks;
        }
      }
    }

    &.commented-via-sms {
      .label i.icon {
        color: $commented-via-sms;
      }

      .content {
        .date {
          color: color.adjust($commented-via-sms, $saturation: -40%, $space: hsl) !important
        }

        .summary, .text.extra {
          color: $commented-via-sms;
        }
      }
    }

    &.commented-via-email {
      .label i.icon {
        color: $commented-via-email;
      }

      .content {
        .date {
          color: color.adjust($commented-via-email, $saturation: -40%, $space: hsl) !important
        }

        .summary, .text.extra {
          color: $commented-via-email;
        }
      }
    }

    &.payment-issue {
      .label i.icon {
        color: $payment-issue;
      }

      .content {
        .date {
          color: color.adjust($payment-issue, $saturation: -40%, $space: hsl) !important
        }

        .summary, .text.extra {
          color: $payment-issue;
        }
      }
    }

    &.commented-via-sms-and-email {
      .label i.icon {
        color: $commented-via-sms-and-email;
      }

      .content {
        .date {
          color: color.adjust($commented-via-sms-and-email, $saturation: -40%, $space: hsl) !important
        }

        .summary, .text.extra {
          color: $commented-via-sms-and-email;
        }
      }
    }

    &.called-via-phone {
      .label i.icon {
        color: $called-via-phone;
      }

      .content {
        .date {
          color: color.adjust($called-via-phone, $saturation: -40%, $space: hsl) !important
        }

        .summary, .text.extra {
          color: $called-via-phone;
        }
      }
    }
  }
}
